import React from "react"
import styled from "styled-components"
import { Input } from "antd"


const CustomInput = ({
    value,
    onChange,
    name,
    placeholder,
    type,
    label,
    isRequired = false,
    error = "",
    showError = true,
    onKeyPress
}) => {
    return (
        <Container error={error.length > 0}>  
            {label ? (
                <Label>
                    {isRequired ? <span style={{color: "#e9540d", marginRight:"3px"}}>*</span>:null}
                    {label}
                    </Label>
            ) : (null)}
            <Input 
                className={error.trim().length > 0 ? "ant-input-error":""}
                value={value}
                onChange={onChange}
                name={name}
                placeholder={placeholder}
                type={type}
                onKeyPress={onKeyPress}
            />
            {error.trim().length > 0 && showError ? (
                <Helper>{error}</Helper>
            ) : (null)}
        </Container>
    )
}

export default CustomInput

const Helper = styled.p`
    margin: 0;
    color: #fb2809;
`

const Label = styled.h3`
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bolder;
    margin: 0 0 5px 2px;
    font-family: 'Oswald Light';
`

const Container = styled.div`
    margin: ${props => props.error ? `0 0 3px 0` : `0 0 20px 0`};
    width: 100%;
`