import React from "react"
import styled from "styled-components"
import Confetti from "react-confetti"
import LogoSrc from "../assets/img/rideup_logo.svg"
import moment from 'moment'
import 'moment/locale/fr'

var QRCode = require('qrcode.react')

const maDate = new Date()
moment.locale('fr')

const WinnerView = ({ }) => {


    return (
        <Container>
            <Inner>
                <LogoContainer>
                    <Logo src={LogoSrc} />
                </LogoContainer>
                <QRContainer>
                    <QRCode value="ixon-gagnant-normal" />
                </QRContainer>
                <RemiseContainer>
                    <Remise>-10 % discount</Remise>
                </RemiseContainer>
                <TextContainer>
                    <Text>Ixon thanks you. Visit your nearest booth to take advantage of your special discount.</Text>
                </TextContainer>
                <DateContainer>
                    <DateText>{moment().format('MMMM Do YYYY, hh:mm:ss a')}</DateText>
                </DateContainer>
            </Inner>
        </Container>
    )
}

export default WinnerView

const DateText = styled.p`
    text-align: center;
    font-size: 12px;
    color: white;
`

const DateContainer = styled.div`
    margin-top: 30px;
`

const Inner = styled.div`
`

const QRContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
`

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 0 20px;
`

const Text = styled.p`
    text-align: center;
    color: white;
`

const Container = styled.div`
    display: flex;
    justify-content: center;
`

const LogoContainer = styled.div`
    width: 100%;
    margin-top: 50px;
`

const Logo = styled.img`
    width: 100%;
    height: 54px;
    object-fit: cover;
`


const RemiseContainer = styled.div`
    margin-top: 50px;
`

const Remise = styled.h1`
    font-family: 'Oswald-Medium',sans-serif;
    font-weight: 600;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    -webkit-letter-spacing: 0.8px;
    -moz-letter-spacing: 0.8px;
    -ms-letter-spacing: 0.8px;
    letter-spacing: 0.8px;
    text-align: center;
    text-transform: uppercase;
    color: white;
`