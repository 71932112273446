import React, { CSSProperties } from "react"
import styled from 'styled-components'
import { Select } from "antd"
const { Option } = Select


const list = {
    "aar": "Afar",
    "abk": "Abkhazian",
    "ace": "Achinese",
    "ach": "Acoli",
    "ada": "Adangme",
    "ady": "Adyghe",
    "afr": "Afrikaans",
    "ain": "Ainu",
    "aka": "Akan",
    "alb": "Albanian",
    "sqi": "Albanian",
    "ale": "Aleut",
    "alt": "Southern Altai",
    "amh": "Amharic",
    "anp": "Angika",
    "ara": "Arabic",
    "arg": "Aragonese",
    "arm": "Armenian",
    "hye": "Armenian",
    "arn": "Mapudungun",
    "arp": "Arapaho",
    "arw": "Arawak",
    "asm": "Assamese",
    "ast": "Asturian",
    "ava": "Avaric",
    "awa": "Awadhi",
    "aym": "Aymara",
    "aze": "Azerbaijani",
    "bak": "Bashkir",
    "bal": "Baluchi",
    "bam": "Bambara",
    "ban": "Balinese",
    "baq": "Basque",
    "eus": "Basque",
    "bas": "Basa",
    "bej": "Beja",
    "bel": "Belarusian",
    "bem": "Bemba",
    "ben": "Bengali",
    "bho": "Bhojpuri",
    "bik": "Bikol",
    "bin": "Bini",
    "bis": "Bislama",
    "bla": "Siksika",
    "bod": "Tibetan",
    "tib": "Tibetan",
    "bos": "Bosnian",
    "bra": "Braj",
    "bre": "Breton",
    "bua": "Buriat",
    "bug": "Buginese",
    "bul": "Bulgarian",
    "bur": "Burmese",
    "mya": "Burmese",
    "byn": "Bilin",
    "cad": "Caddo",
    "car": "Galibi Carib",
    "cat": "Catalan",
    "ceb": "Cebuano",
    "ces": "Czech",
    "cze": "Czech",
    "cha": "Chamorro",
    "che": "Chechen",
    "chi": "Chinese",
    "zho": "Chinese",
    "chk": "Chuukese",
    "chm": "Mari",
    "chn": "Chinook jargon",
    "cho": "Choctaw",
    "chp": "Chipewyan",
    "chr": "Cherokee",
    "chv": "Chuvash",
    "chy": "Cheyenne",
    "cor": "Cornish",
    "cos": "Corsican",
    "cre": "Cree",
    "crh": "Crimean Tatar",
    "csb": "Kashubian",
    "cym": "Welsh",
    "wel": "Welsh",
    "dak": "Dakota",
    "dan": "Danish",
    "dar": "Dargwa",
    "del": "Delaware",
    "den": "Slave",
    "deu": "German",
    "ger": "German",
    "dgr": "Dogrib",
    "din": "Dinka",
    "div": "Dhivehi",
    "doi": "Dogri",
    "dsb": "Lower Sorbian",
    "dua": "Duala",
    "dut": "Dutch",
    "nld": "Dutch",
    "dyu": "Dyula",
    "dzo": "Dzongkha",
    "efi": "Efik",
    "eka": "Ekajuk",
    "ell": "Modern Greek",
    "gre": "Modern Greek",
    "eng": "English",
    "est": "Estonian",
    "ewe": "Ewe",
    "ewo": "Ewondo",
    "fan": "Fang",
    "fao": "Faroese",
    "fas": "Persian",
    "per": "Persian",
    "fat": "Fanti",
    "fij": "Fijian",
    "fil": "Filipino",
    "fin": "Finnish",
    "fon": "Fon",
    "fra": "France",
    "frr": "Northern Frisian",
    "frs": "Eastern Frisian",
    "fry": "Western Frisian",
    "ful": "Fulah",
    "fur": "Friulian",
    "gaa": "Ga",
    "gay": "Gayo",
    "gba": "Gbaya",
    "geo": "Georgian",
    "kat": "Georgian",
    "gil": "Gilbertese",
    "gla": "Gaelic",
    "gle": "Irish",
    "glg": "Galician",
    "glv": "Manx",
    "gon": "Gondi",
    "gor": "Gorontalo",
    "grb": "Grebo",
    "grn": "Guarani",
    "gsw": "Swiss German",
    "guj": "Gujarati",
    "gwi": "Gwichʼin",
    "hai": "Haida",
    "hat": "Haitian",
    "hau": "Hausa",
    "haw": "Hawaiian",
    "heb": "Hebrew",
    "her": "Herero",
    "hil": "Hiligaynon",
    "hin": "Hindi",
    "hmn": "Hmong",
    "hmo": "Hiri Motu",
    "hrv": "Croatian",
    "hsb": "Upper Sorbian",
    "hun": "Hungarian",
    "hup": "Hupa",
    "iba": "Iban",
    "ibo": "Igbo",
    "ice": "Icelandic",
    "isl": "Icelandic",
    "iii": "Sichuan Yi",
    "iku": "Inuktitut",
    "ilo": "Iloko",
    "ind": "Indonesian",
    "inh": "Ingush",
    "ipk": "Inupiaq",
    "ita": "Italian",
    "jav": "Javanese",
    "jpn": "Japanese",
    "jpr": "Judeo-Persian",
    "jrb": "Judeo-Arabic",
    "kaa": "Kara-Kalpak",
    "kab": "Kabyle",
    "kac": "Kachin",
    "kal": "Kalaallisut",
    "kam": "Kamba",
    "kan": "Kannada",
    "kas": "Kashmiri",
    "kau": "Kanuri",
    "kaz": "Kazakh",
    "kbd": "Kabardian",
    "kha": "Khasi",
    "khm": "Central Khmer",
    "kik": "Kikuyu",
    "kin": "Kinyarwanda",
    "kir": "Kirghiz",
    "kmb": "Kimbundu",
    "kok": "Konkani",
    "kom": "Komi",
    "kon": "Kongo",
    "kor": "Korean",
    "kos": "Kosraean",
    "kpe": "Kpelle",
    "krc": "Karachay-Balkar",
    "krl": "Karelian",
    "kru": "Kurukh",
    "kua": "Kuanyama",
    "kum": "Kumyk",
    "kur": "Kurdish",
    "kut": "Kutenai",
    "lad": "Ladino",
    "lah": "Lahnda",
    "lam": "Lamba",
    "lao": "Lao",
    "lav": "Latvian",
    "lez": "Lezghian",
    "lim": "Limburgan",
    "lin": "Lingala",
    "lit": "Lithuanian",
    "lol": "Mongo",
    "loz": "Lozi",
    "ltz": "Luxembourgish",
    "lua": "Luba-Lulua",
    "lub": "Luba-Katanga",
    "lug": "Ganda",
    "lui": "Luiseno",
    "lun": "Lunda",
    "luo": "Luo",
    "lus": "Lushai",
    "mac": "Macedonian",
    "mkd": "Macedonian",
    "mad": "Madurese",
    "mag": "Magahi",
    "mah": "Marshallese",
    "mai": "Maithili",
    "mak": "Makasar",
    "mal": "Malayalam",
    "man": "Mandingo",
    "mao": "Maori",
    "mri": "Maori",
    "mar": "Marathi",
    "mas": "Masai",
    "may": "Malay",
    "msa": "Malay",
    "mdf": "Moksha",
    "mdr": "Mandar",
    "men": "Mende",
    "mic": "Mi'kmaq",
    "min": "Minangkabau",
    "mlg": "Malagasy",
    "mlt": "Maltese",
    "mnc": "Manchu",
    "mni": "Manipuri",
    "moh": "Mohawk",
    "mon": "Mongolian",
    "mos": "Mossi",
    "mus": "Creek",
    "mwl": "Mirandese",
    "mwr": "Marwari",
    "myv": "Erzya",
    "nap": "Neapolitan",
    "nau": "Nauru",
    "nav": "Navajo",
    "nbl": "South Ndebele",
    "nde": "North Ndebele",
    "ndo": "Ndonga",
    "nds": "Low German",
    "nep": "Nepali",
    "new": "Nepal Bhasa",
    "nia": "Nias",
    "niu": "Niuean",
    "nno": "Norwegian Nynorsk",
    "nob": "Norwegian Bokmål",
    "nog": "Nogai",
    "nor": "Norwegian",
    "nqo": "N'Ko",
    "nso": "Pedi",
    "nya": "Chichewa",
    "nym": "Nyamwezi",
    "nyn": "Nyankole",
    "nyo": "Nyoro",
    "nzi": "Nzima",
    "oci": "Occitan",
    "oji": "Ojibwa",
    "ori": "Oriya",
    "orm": "Oromo",
    "osa": "Osage",
    "oss": "Ossetian",
    "pag": "Pangasinan",
    "pam": "Pampanga",
    "pan": "Panjabi",
    "pap": "Papiamento",
    "pau": "Palauan",
    "pol": "Polish",
    "pon": "Pohnpeian",
    "por": "Portuguese",
    "pus": "Pushto",
    "que": "Quechua",
    "raj": "Rajasthani",
    "rap": "Rapanui",
    "rar": "Rarotongan",
    "roh": "Romansh",
    "rom": "Romany",
    "ron": "Romanian",
    "rum": "Romanian",
    "run": "Rundi",
    "rup": "Aromanian",
    "rus": "Russian",
    "sad": "Sandawe",
    "sag": "Sango",
    "sah": "Yakut",
    "sas": "Sasak",
    "sat": "Santali",
    "scn": "Sicilian",
    "sco": "Scots",
    "sel": "Selkup",
    "shn": "Shan",
    "sid": "Sidamo",
    "sin": "Sinhala",
    "slo": "Slovak",
    "slk": "Slovak",
    "slv": "Slovenian",
    "sma": "Southern Sami",
    "sme": "Northern Sami",
    "smj": "Lule Sami",
    "smn": "Inari Sami",
    "smo": "Samoan",
    "sms": "Skolt Sami",
    "sna": "Shona",
    "snd": "Sindhi",
    "snk": "Soninke",
    "som": "Somali",
    "sot": "Southern Sotho",
    "spa": "Spanish",
    "srd": "Sardinian",
    "srn": "Sranan Tongo",
    "srp": "Serbian",
    "srr": "Serer",
    "ssw": "Swati",
    "suk": "Sukuma",
    "sun": "Sundanese",
    "sus": "Susu",
    "swa": "Swahili",
    "swe": "Swedish",
    "syr": "Syriac",
    "tah": "Tahitian",
    "tam": "Tamil",
    "tat": "Tatar",
    "tel": "Telugu",
    "tem": "Timne",
    "ter": "Tereno",
    "tet": "Tetum",
    "tgk": "Tajik",
    "tgl": "Tagalog",
    "tha": "Thai",
    "tig": "Tigre",
    "tir": "Tigrinya",
    "tiv": "Tiv",
    "tkl": "Tokelau",
    "tli": "Tlingit",
    "tmh": "Tamashek",
    "tog": "Tonga",
    "ton": "Tonga",
    "tpi": "Tok Pisin",
    "tsi": "Tsimshian",
    "tsn": "Tswana",
    "tso": "Tsonga",
    "tuk": "Turkmen",
    "tum": "Tumbuka",
    "tur": "Turkish",
    "tvl": "Tuvalua",
    "twi": "Twi",
    "tyv": "Tuvinian",
    "udm": "Udmurt",
    "uig": "Uighur",
    "ukr": "Ukrainian",
    "umb": "Umbundu",
    "urd": "Urdu",
    "uzb": "Uzbek",
    "vai": "Vai",
    "ven": "Venda",
    "vie": "Vietnamese",
    "vot": "Votic",
    "wal": "Wolaitta",
    "war": "Waray",
    "was": "Washo",
    "wln": "Walloon",
    "wol": "Wolof",
    "xal": "Kalmyk",
    "xho": "Xhosa",
    "yao": "Yao",
    "yap": "Yapese",
    "yid": "Yiddish",
    "yor": "Yoruba",
    "zap": "Zapotec",
    "zen": "Zenaga",
    "zgh": "Standard Moroccan Tamazight",
    "zha": "Zhuang",
    "zul": "Zulu",
    "zun": "Zuni",
    "zza": "Zaza"
}

const options = Object.keys(list).map(key => {
    return {
        value: key,
        label: list[key]
    }
})

const CountrySelect = ({ 
    defaultCode = null,
    value,
    onChange,
    name,
    placeholder,
    label,
    isRequired = true,
    error = "",
    showError = true,
    size = "middle",
    style = null,
    disabled = false,
    nullable = false
}) => {

    const handleChange = (value) => {
        onChange(value, name)
    }

    return (
            <Container error={error.length > 0}>  
            {label ? (
                <Label>
                    {isRequired ? <span style={{color: "#e9540d", marginRight:"3px"}}>*</span>:null}
                    {label}
                    </Label>
            ) : (null)}
                <Select
                    className={`antd-select-${size}`}
                    style={style ? style : {
                        width: "100%"
                    }}
                    placeholder={placeholder}
                    onChange={handleChange}
                    size={size}
                    value={value}
                    disabled={disabled}

                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {nullable ? (
                        <Option 
                            style={{fontFamily: 'Roboto Regular'}}
                            value={null}
                        >{" "}</Option>
                    ) : (null)}
                    {options.map(option => (
                        <Option 
                            key={option.value}
                            value={option.value}
                        >{option.label}</Option>
                    ))}
                </Select>
            {error.trim().length > 0 && showError ? (
                <Helper>{error}</Helper>
            ) : (null)}
        </Container>
    )
}

export default CountrySelect

const Helper = styled.p`
    margin: 0;
    color: #fb2809;
`

const Label = styled.h3`
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bolder;
    margin: 0 0 5px 2px;
    font-family: 'Oswald Light';
`

const Container = styled.div`
    margin: ${props => props.error ? `0 0 3px 0` : `0 0 20px 0`};
`
