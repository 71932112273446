import React, { useEffect } from 'react'

import GlobalFonts from './style/fonts/fonts'
import BaseTheme from './style/themes/IxonTheme'

import { ThemeProvider } from 'styled-components'

import "./style/css/IxonTheme.css"

const Theme = ({ children }) => {

	useEffect(() => {
		document.body.style.backgroundColor = "#151515"
	})

	return (
		<ThemeProvider theme={BaseTheme}>
			<GlobalFonts />
			{children}
		</ThemeProvider>
	)
}

export default Theme