import React, { useState } from "react"
import styled from "styled-components"

import { Checkbox } from "antd"

import CountrySelect from "./dataEntry.js/CountrySelect"
import CustomInput from "./dataEntry.js/CustomInput"
import CustomSelect from "./dataEntry.js/CustomSelect"
import CustomButton from "./cta/CustomButton"

import IxonForm from "../api/IxonForm"

import {
    UpOutlined,
    DownOutlined
  } from '@ant-design/icons'

const bicycleUsageOptions = [
    {value: "roadster", label: "Roadster"},
    {value: "rising", label: "Rising"},
    {value: "sport", label: "Sport"},
    {value: "trail", label: "Trail"},
    {value: "custom", label: "Custom"},
    {value: "urban", label: "Urban"}
]

const Form = ({ setFormIsSubmitted, setIsSpecialWinner, formIsSubmitted, isSpecialWinner }) => {

    const isValid = () => {
        let isValid = true
        let newErrs = {...errors}
        if(form.lastname.trim().length === 0) {
            newErrs = {...newErrs, lastname: "champs obligatoire"}
            isValid = false
        }
        if(form.firstname.trim().length === 0) {
            newErrs = {...newErrs, firstname: "champs obligatoire"}
            isValid = false
        }
        if(form.email.trim().length === 0) {
            newErrs = {...newErrs, email: "champs obligatoire"}
            isValid = false
        }
        if(form.motorcycleModel.trim().length === 0) {
            newErrs = {...newErrs, motorcycleModel: "champs obligatoire"}
            isValid = false
        }
        if(!form.nationality) {
            newErrs = {...newErrs, nationality: "champs obligatoire"}
            isValid = false
        }
        if(!form.motorcycleUsage) {
            newErrs = {...newErrs, motorcycleUsage: "champs obligatoire"}
            isValid = false
        }
        if(!form.consent) {
            newErrs = {...newErrs, consent: "champs obligatoire"}
            isValid = false
        }
        setErrors(newErrs)
        return isValid
    }

    const [ form, setForm ] = useState({
        lastname: "",
        firstname: "",
        nationality: null,
        motorcycleUsage: null,
        email: "",
        motorcycleModel: "",
        consent: false
    })

    const [ expand, setExpand ] = useState(false)

    const [ errors, setErrors ] = useState({
        lastname: "",
        firstname: "",
        nationality: "",
        motorcycleUsage: "",
        email: "",
        motorcycleModel: "",
        consent: ""
    })

    const handleChange = ({ currentTarget }) => {   
        setForm({...form, [currentTarget.name]: currentTarget.value})
        setErrors({...errors, [currentTarget.name]: ""})
    }

    const handleValueChange = (value, name) => {
        setForm({...form, [name]: value})
        setErrors({...errors, [name]: ""})
    }

    const handleCheckConsent = ({ target }) => {
        setForm({...form, consent: target.checked})
        setErrors({...errors, consent: ""})
    }

    const handleSubmit = () => {

        if(isValid()) {
            setFormIsSubmitted(true)
            IxonForm.submit(form).then(res => {
                if(res.status === 200) {
                    res.json().then(data => {
                        if(!data.isValid) {
                            let newErrors = {...errors}
                            data.errors.map(error => {
                                newErrors = {...newErrors, [error.errorField]: error.error}
                            })
                            setFormIsSubmitted(false)
                            setErrors(newErrors)
                        } else {
                            if(data.specialWinner) {
                                setIsSpecialWinner(true)
                            }
                        }
                    })
                }
            })
        }
    }

    return (
        <Container>
            <Inner>
            <CustomInput 
                label="Lastname" 
                isRequired={true}
                onChange={handleChange}
                name="lastname"
                value={form.lastname}
                placeholder=""
                error={errors.lastname}
            />
            <CustomInput 
                label="Firstname" 
                isRequired={true}
                onChange={handleChange}
                name="firstname"
                value={form.firstname}
                placeholder=""
                error={errors.firstname}
            />
            <CustomInput
                label="Email" 
                isRequired={true}
                onChange={handleChange}
                name="email"
                value={form.email}
                placeholder=""
                error={errors.email}
            />
            <CountrySelect 
                value={form.nationality}
                isRequired={true}
                onChange={handleValueChange}
                name="nationality"
                placeholder=""
                label="Nationality"
                error={errors.nationality}
            />
            <CustomSelect
                options={bicycleUsageOptions}
                isRequired={true}
                label="Motorcycle use"
                name="motorcycleUsage"
                onChange={handleValueChange}
                value={form.motorcycleUsage}
                nullable={false}
                placeholder=""
                error={errors.motorcycleUsage}
            />
            <CustomInput
                isRequired={true}
                label="Motorcycle model" 
                onChange={handleChange}
                name="motorcycleModel"
                value={form.motorcycleModel}
                placeholder=""
                error={errors.motorcycleModel}
            />
            <div style={{display: "flex", alignItems:"center"}}>
                <Checkbox
                    checked={form.consent}
                    onChange={handleCheckConsent}
                >
                        <p style={{margin: 0, color: "#FFFFFF"}}>I consent</p>
                        
                </Checkbox>
                <Link onClick={() => setExpand(!expand)}>{expand ? "hide":"show"}</Link>
            </div>

            {expand ? (
                <RGPD>
                    {`The information that concerns you is intended exclusively for the site ${window.location.href}. You have the right to access, modify, rectify and delete data concerning you (art. 34 of the law "Informatique et Libertés"). Consent: Use of data in the context of the contact request and the commercial relationship that may result.

                    By sending us this form, you agree that Ixon may store and use your personal data collected in this form for the purpose of commercial relations. In this case, you authorize Ixon to communicate occasionally with you if it deems appropriate in order to inform you of the latest news of our Company, its commercial actions, via the data collected in the form.
                    In order to protect the confidentiality of your personal data, Ixon undertakes not to disclose, transmit, or share your personal data with any other entities, companies or organizations whatsoever, in accordance with the General Data Protection Regulation of 2018 on the protection of personal data. To know and exercise your rights, including withdrawing consent to the use of your data collected through this form, contact us.)`}
                </RGPD>
            ) : (null)}

            <Footer>
                {formIsSubmitted ? (null) : (<CustomButton onClick={handleSubmit}>Confirm</CustomButton>)}
            </Footer>
            </Inner>
           
        </Container>
    )
}

export default Form

const RGPD = styled.p`
    font-size: 8px;
    font-style: italic;
    margin-top: 10px;
    color: white;
`

const Link = styled.p`
    margin: 0;
    color: #e8561f;
    text-align: center;
    font-size: 13px;
    cursor: pointer;
    margin-top: 2px;
`

const Inner = styled.div`
    width: 100%;
    max-width: 500px;
`

const Container = styled.div`
    margin: 0 25px;
    padding: 20px;
    display: flex;
    justify-content: center;
`

const Footer = styled.div`
    margin-top: 25px;
`