import React, { useState } from "react"

import Theme from './Theme'

import Form from "./components/Form"
import FormTemplate from "./components/FormTemplate"
import SpecialWinnerView from "./components/SpecialWinnerView"
import WinnerView from "./components/WinnerView"


function App() {
    const [ isSpecialWinner, setIsSpecialWinner ] = useState(false)
    const [ formIsSubmitted, setFormIsSubmitted ] = useState(false)

    if(formIsSubmitted && isSpecialWinner) {
        return (
            <WinnerView />
        )
    } else if(formIsSubmitted) {
        return (
            <SpecialWinnerView />
        )
    } else {
        return (
            <Theme>
                <FormTemplate>
                    <Form 
                        setIsSpecialWinner={setIsSpecialWinner}
                        setFormIsSubmitted={setFormIsSubmitted}
                        isSpecialWinner={isSpecialWinner}
                        formIsSubmitted={formIsSubmitted}
                    />
                </FormTemplate>
            </Theme>
        )
    }



}

export default App

