import React from "react"
import styled from "styled-components"
import LogoSrc from "../assets/img/rideup_logo.svg"

const FormTemplate = ({ children }) => {

    return ( 
        <Container>
            <Inner> 
                <LogoContainer>
                    <Logo src={LogoSrc} />
                </LogoContainer>
                <Header>
                    <Titles>
                        <Title>Welcome</Title>
                    </Titles>
                </Header>
                {children}
            </Inner>
        </Container>
    )
}

export default FormTemplate

const Background = styled.img`
    position: absolute;
`

const LogoContainer = styled.div`
    width: 100%;
`

const Logo = styled.img`
    width: 100%;
    height: 54px;
    object-fit: cover;
`

const Header = styled.div`
    display: flex;
    justify-content: center;
`

const Inner = styled.div`
    width: 100%;
`

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 35px;
    position: relative;
`

const Titles = styled.div`
    text-align: center;
    margin: 5px 20px 0 20px;
    font-size: 14px;
    font-family: 'Oswald Medium';
`

const SubTitle = styled.h3`
    text-align: center;
`

const Title = styled.h1`    
    font-family: 'Oswald-Medium', sans-serif;
    font-size: 27px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    text-align: center;
    color: #e9540d;
    margin-top: 35px;
    text-transform: uppercase;
`