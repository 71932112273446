const theme = {
	bio: "#E0FF4F",
	success: "#1ae333",
	warning: "#FF6663",
	danger: "#FF6663",
	info: "#1A8FE3",
	typo: "#353434",
	icon: "#565656",
	placeholder: "#4c4f54c4"
}

export default theme