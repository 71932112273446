import React from "react"
import styled from "styled-components"

import { Select } from "antd"
const { Option } = Select

const CustomSelect = ({
    options = [], 
    label,
    name,
    onChange,
    isRequired = false,
    error = "",
    showError = true,
    placeholder = "",
    size = "middle",
    value = null,
    style = null,
    nullable = false,
    disabled = false,
    loading = false
}) => {

    const handleChange = (value) => {
        if(!value)
            value = ""
        onChange(value, name)
    }

    return (
        <Container error={error.length > 0}>  
        {label ? (
            <Label>
                {isRequired ? <span style={{color: "#e9540d", marginRight:"3px"}}>*</span>:null}
                {label}
                </Label>
        ) : (null)}
            <Select
                className={`antd-select-${size}`}
                style={style ? style : {
                    width: "100%"
                }}
                placeholder={placeholder}
                onChange={handleChange}
                size={size}
                value={value}
                disabled={disabled}
                allowClear={nullable || !isRequired}
                loading={loading}
            >
                {nullable ? (
                    <Option 
                        value={" "}
                    >{" "}</Option>
                ) : (null)}
                {options.map(option => (
                    <Option 
                        key={option.value}
                        value={option.value}
                    >{option.label}</Option>
                ))}
            </Select>
        {error.trim().length > 0 && showError ? (
            <Helper>{error}</Helper>
        ) : (null)}
    </Container>
    )
}

export default CustomSelect

const Helper = styled.p`
    margin: 0;
    color: #fb2809;
`

const Label = styled.h3`
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bolder;
    margin: 0 0 5px 2px;
    font-family: 'Oswald Light';
`

const Container = styled.div`
    margin: ${props => props.error ? `0 0 3px 0` : `0 0 20px 0`};
`