import React from "react"
import styled from "styled-components"

const CustomButton = ({ children, onClick }) => {

    return (
        <Container onClick={onClick}>
            {children}
        </Container>
    )
}

export default CustomButton

const Container = styled.div`
    font-family: 'Oswald-Medium',sans-serif;
    font-weight: 600;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    -webkit-letter-spacing: 0.8px;
    -moz-letter-spacing: 0.8px;
    -ms-letter-spacing: 0.8px;
    letter-spacing: 0.8px;
    text-align: center;
    padding: 12px 35px;
    border-radius: 0px;
    background-color: #e8561f;
    display: table;
    margin: auto;
    text-transform: uppercase;
    color: white;
    border-radius: 5px;
    &:hover {
        background-color: black;
        color: white;
        cursor: pointer;
    }
`