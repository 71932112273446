import { createGlobalStyle } from 'styled-components'

import OswaldMediumWoff from './medium.woff'
import OswaldMediumWoff2 from './medium.woff2'

import lightWoff from './light.woff'
import lightWoff2 from './light.woff2'

import heavyWoff from './heavy.woff'
import heavyWoff2 from './heavy.woff2'

export default createGlobalStyle`
    @font-face {
        font-family: 'Oswald Light';
        src: url(${lightWoff}) format('woff'),
                url(${lightWoff2}) format('woff2');
    },
    @font-face {
        font-family: 'Oswald Medium';
        src: url(${OswaldMediumWoff}) format('woff'),
       		 url(${OswaldMediumWoff2}) format('woff2');
    },
    @font-face {
        font-family: 'Oswald Heavy';
        src: url(${heavyWoff}) format('woff'),
       		 url(${heavyWoff2}) format('woff2');
    }
`;